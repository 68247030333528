import React from "react"
import MoonLoader from "react-spinners/MoonLoader"
import * as styles from "./button.module.scss"

export default ({ onClick, style, children, type, disabled, className, loaderColor }) => {
  const [loading, setLoading] = React.useState(false)

  async function onButtonClick() {
    setLoading(true)
    await onClick()
    setLoading(false)
  }

  return (
    <button disabled={disabled} type={type} onClick={onClick && !loading ? onButtonClick : null} className={`${styles.button} ${className}`} style={style}>
      {loading ? (
        <MoonLoader size={18} color={loaderColor || "#ffffff"} loading={true} />
      ) : (
        children
      )}
    </button>
  )
}
