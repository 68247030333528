import React from 'react'
import { Helmet } from "react-helmet";
import Layout from '../components/layout'
import SuccessPage from '../components/Pages/SuccessPage/SuccessPage'
export default () => {
    return(
        <Layout>
            <SuccessPage />
        </Layout>
    )
}