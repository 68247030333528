import React from "react";
import CheckSvg from "../../../assets/svg/check.svg";
import { Link } from "gatsby";
import Button from "../../Common/Button/Button";
import * as styles from "./successpage.module.scss";
import { push_event_to_datalayer } from '../../../tools/analytics'

export default () => {
  push_event_to_datalayer('stripe-checkout-completed')
  return (
    <div className={styles.successPageContainer}>
      <CheckSvg />
      <h1>Payment Successful</h1>
      <h2>
        Your account has been credited. Please check your e-mail for the
        confimation letter.
      </h2>
      <Link to={"/editor/2D"}>
        <Button>Go to maker</Button>
      </Link>
    </div>
  );
};
